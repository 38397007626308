.ticket_quantity_container {
    background-color: #f6f6f6;
    height: 610px;
    width: 530px;
    margin: 0 auto;
    padding-bottom: 20px;
    /* padding: 20px 8px; */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}
.ticket_type_box {
    width: 100%;
    position: relative;
}
.ticket_type_box > h1 {
    background: white;
    /* text-align: center; */
    font-size: 23px;
    padding: 10px;
    color: #022268;
    margin-bottom: 30px;
    padding: 16px;
}
.ticket_type_box > div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 20px;
}
.ticket_type_box > div:hover {
    cursor: pointer;
}
.ticket_type_box > div p {
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 600;
    color: rgb(34, 34, 34);
}
.ticketPrice {
    color: rgb(102, 102, 102);
    font-size: 13px;
    margin: 10px 0 0 0;
}
.crossSvg {
    height: 15px;
    position: absolute;
    right: 24px;
    top: 21px;
}
.crossSvg:hover {
    cursor: pointer;
}
.quantityBox2 {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: end;
}

.quantityBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.quantityBox > div {
    min-width: 35px;
    text-align: center;
}
.fa-circle-plus,
.fa-circle-minus {
    font-size: 25px;
}
.faColorEnable {
  color:#ff7600;

}
.faColorDisable {
  color: #8c8c8c;

}
.ticketNextBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    background: white;
    padding: 10px 16px;
}
.ticketNextBtn-qty {
    font-size: 12px;
    color: rgb(102, 102, 102);
}
.ticketNextBtn-price {
    font-size: 16px;
    color: black;
    font-size: 20px;
    color: black;
    font-weight: 600;
}

.ticket-diff-types > div {
    width: 100%;
}
.ticketInfo--text {
    font-size:10px !important;
    font-weight: 500 !important;
}
.ticket-diff-types> div >* {
    display: flex;
    justify-content: space-between;
    margin-bottom:10px !important;
}
.ticket-diff-types > div h4 {
    font-size: 18px;
    /* margin-bottom: 0; */   
}
.finalPrice {
    font-weight: 600;
    font-size: 16px;
}
.ticket_type_box > h2 {
    font-size: 16px;
}
.next-svg {
    height: 20px !important;
    width: 20px !important;
}
.btn-continue {
    background: linear-gradient(90deg, #f7941d 5.93%, #f15a22 89.95%);
    border: 1px solid #f15a22;
    box-sizing: border-box;
    border-radius: 10px;
    /* height: 52px; */
    padding: 14px 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ffffff;
}

.btn-continue:hover {
    cursor: pointer;
    opacity:0.9;
}

.sold-out {
    color:rgba(255, 0, 0, 0.829);
}

@media only screen and (max-width:592px) {
    .ticket_quantity_container {
        width: 100%;
    }
    .ticket-diff-types > div > h4 > .tickDescription {
        width: 84%;
    }
    .ticket-diff-types {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    }

    .mobile-ticket-style {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
     
}