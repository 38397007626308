.profile-password-container {
    min-height: 100vh;
}
.white-star {
    position: absolute;
    top: 22%;
    left: 11vw;
}
.yellow-hexagon-p {
    position: absolute;
    top: 55%;
    left: 9vw;
}
.active-line-p {
    position: absolute;
    top: 74%;
    left: 19vw;
}
.yellow-hexagon-2 {
    position: absolute;
    top: 18%;
    left: 39vw;
}
.hexagon-blue-big-p {
    position: absolute;
    top: 75%;
    left: 67vw;
}
.hexagon-yellow-star-p {
    position: absolute;
    top: 23%;
    left: 81vw;
}
.profile-password {
    width: 50vw;
    background: #fff;
    z-index: 99998;
    position: absolute;
    top: 54%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 24px;
    padding: 60px;
    box-shadow: -3px 10px 15px 3px rgba(0, 0, 0, 0.1);
}
.profile-password > h2 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 100%;
    /* identical to box height, or 34px */
    margin-bottom: 16px;
    text-align: center;
    letter-spacing: -0.045em;

    /* Text/Dark */

    color: #292930;
}
.profile-password > p {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    margin: 16px 0 !important;
    text-align: center;
    letter-spacing: -0.025em;

    /* Text/Grey */

    color: #474849;
}

.profile-password-buttons {
    display: flex;
    flex-direction: row;
    margin: 40px 0 0 0;
    justify-content: center;
}
.confirm-email {
    background: linear-gradient(90deg, #f7941d 5.93%, #f15a22 89.95%);
    border: 1px solid #f15a22;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 12px 18px;
    margin-right: 40px;
    color:white;
}
.cancel-email {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    /* identical to box height, or 120% */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30%;
    text-align: right;
    letter-spacing: 0.015em;
    background-color: transparent;
    border: 0px solid transparent;
    color: #000000;
}

@media only screen and (max-width: 992px) {
    .profile-password {
        width: 75vw;
    }
}

@media only screen and (max-width: 792px) {
    .profile-password {
        width: 85vw;
    }
}

@media only screen and (max-width: 592px) {
    .profile-password {
        width: 95vw;
        padding: 40px 20px ;
    }
}
