.nft-marketplace {
  min-height:750px;
  margin-top:150px;
  
}
.my-ticket-card {
  display: flex;
  justify-content: center;

}
.dropin-container-hide {
  display: none;
}
.dropin-container-show {
  display: block;
  top: 0;
  min-height: 100vh;
  width: 100vw;
  z-index: 1055002 !important;
  background-color: #00000052;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1055002 !important;
  background-color: #00000052;
}
.dropin-parent {
  width: 380px;
}
.upcomingEventsNav {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 18px 0px;
  
  margin-bottom:10px;

  width: fit-content;
  margin: 10px auto;
}
.upcomingEventsNav > h4 {
  margin:0 20px;
  opacity:0.75;
}
.upcomingEventsNav > h4:hover {
  cursor:pointer;
  color:#F15A22;
  opacity:1;
}
.activeMarket {
  color:#F15A22;
  padding-bottom:6px;
  border-bottom: 1px solid #80808066;
  opacity:1 !important;
}
.marketplace-heading {
  font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 34px;
    /* identical to box height, or 113% */
    margin: 50px 0 10px 0;
    letter-spacing: 0.01em;
    color: #0b3a6a;
    text-align: center;
}
.background-elements{
  position: relative;
}

.marketplace-hexagon-a{
  position: absolute;
  top:400px;
  left:2vw;
}
.marketplace-hexagon-b{
  position: absolute;
  top:300px;
  right:2vw;
}
.marketplace-hexagon-c{
  position: absolute;
  top:700px;
  right:6vw;
}
.marketplace-star-e {
  position: absolute;
  top:200px;
  left:10vw;
}

@media only screen and (max-width:570px) {
  .my-ticket-card {
    width:50% !important;
  }
}