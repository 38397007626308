.sign-container {
    min-height: 140vh;
    margin-bottom: 40px;
}
.sign-in-top{
    display:none !important;
}
.sign-left {
    width: 44%;
}
.sign-left > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 42px;
    line-height: 100%;
    /* or 42px */
    letter-spacing: -0.055em;
    /* Color/White */
    color: #ffffff;
    margin-bottom: 30px;
}
.sign-left > p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */
    letter-spacing: 0.01em;
    color: #ffffff;
}
.sign-container > div {
    height: 30vh;
    z-index: 999;
    position: relative;
    display: flex;
    justify-content: space-evenly;
    /* align-items: center; */
    flex-direction: row;
}

.sign-right > h2 {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 100%;
    /* identical to box height, or 34px */
    margin-bottom: 20px;
    letter-spacing: -0.045em;

    /* Text/Dark */

    color: #292930;
}
.sign-right > p {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    margin-bottom: 64px;
    letter-spacing: -0.01em;
    color: #999fae;
}

.l-in-input {
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.l-input-element {
    display: flex;
    flex-direction: column;
    position: relative;
}
.l-input-element > div > label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 99.33%;
    /* identical to box height, or 14px */

    letter-spacing: -0.025em;
    color: #292930;
}
.l-input-element > input {
    width: 100%;
    height: 54px;
    /* Color/White */
    background: #ffffff;
    /* Stroke/Super-Light */
    border: 1px solid #e3e6e9;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 72px;
}
.l-input-element > input::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    transition: 0.2s;
    letter-spacing: -0.025em;

    /* Text/Dark */

    color: #292930;
}
.l-input-element > input:hover,
.l-input-element > input:focus {
    -webkit-box-shadow: 4px 4px 37px -18px rgba(51, 51, 51, 0.61);
    -moz-box-shadow: 4px 4px 37px -18px rgba(51, 51, 51, 0.61);
    box-shadow: 4px 4px 37px -18px rgba(51, 51, 51, 0.61);
    transition: 0.2s;
    outline: none;
    font-size: 16px;
}

.l-icon {
    position: absolute;
    top: 39.5px;
    left: 26px;
    width: 25px;
}
.l-icon-p {
    position: absolute;
    top: 38.5px;
    left: 30px;
    width: 25px;
}


.auto-sign-in {
    display: flex;
    height: 72px;
} 
.auto-sign-in > button, .auto-sign-in > a{
    background: #ffffff;
    /* ✨🖼️ Form, btn, border */
    margin-right: 24px;
    border: 1px solid #c3cad9;
    border-radius: 8px;
    padding: 10px 24px;
    font-family: Poppins;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    /* 🎨 H1, H2 */

    color: #00116c;
    display: flex;
    justify-content: center;
    align-items: center;
}
.auto-sign-in > button:hover{
    cursor: pointer;
}
/* background styling */
.s-active-line {
    position: absolute;
    z-index: 1001;
    width: 72px;
    height: 8px;
    left: 410px;
    top: 385px;
}

.s-hexa-white {
    position: absolute;
    width: 15px;
    height: 15px;
    right: calc(100vw - 600px);
    top: 169px;
    z-index: 10001;
}
.s-hexa-yellow {
    position: absolute;
    width: 34px;
    height: 34px;
    left: 70px;
    top: 573px;
    opacity: 0.7;
}

.s-underscore {
    z-index: 10001;
    position: absolute;
    width: 69px;
    height: 2px;
    left: 243px;
    top: 780px;
}
.s-triangle {
    position: absolute;
    z-index: 10001;
    width: 25px;
    height: 25px;
    left: calc(100vw - 814px);
    top: 394px;
}
.s-hexagon-orange {
    position: absolute;
    width: 37px;
    height: 37px;
    left: calc(100vw - 440px);
    top: 182px;
}
.s-star-trans {
    position: absolute;
    width: 27px;
    height: 27px;
    left: calc(100vw - 100px);
    top: 281px;
}
.s-hexagon-orange-2 {
    position: absolute;
    width: 27px;
    height: 27px;
    left: calc(100vw - 50px);
    top: 706px;
}
.s-hexagon-blue {
    position: absolute;
    width: 158px;
    height: 158px;
    left: calc(100vw - 175px);
    top: 890.08px;
    /* z-index: 10001; */
    overflow: hidden;
}

.s-hr {
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #000;
    line-height: 0.1em;
    margin: 10px 0 20px;
}
.s-hr > span {
    background: #fff;
    padding: 0 10px;
    font-size: 16px;
    position: relative;
    top: 15px;
}
.wallet-icon-1 {
    height: 50px;
}
.wallet-icon-2 {
    height: 70px;
}

@media only screen and (max-width: 1292px) {
    .s-active-line {
        position: absolute;
        z-index: 1001;
        width: 72px;
        height: 8px;
        left: 360px;
        top: 335px;
    }
}
@media only screen and (max-width: 992px) {
    .s-active-line {
        position: absolute;
        z-index: 1001;
        width: 72px;
        height: 8px;
        left: 330px;
        top: 280px;
    }
    .sign-right {
        background: white;
        padding: 40px;
        z-index: 10001;
        position: relative;
        border-radius: 12px;
    }
}
@media only screen and (max-width: 792px) {
    .s-hexa-white {
        position: absolute;
        width: 15px;
        height: 15px;
        right: calc(100vw - 300px);
        top: 169px;
    }
    .sign-container-items {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 20px;
    }
    .sign-left {
        display: none;
    }
    .sign-right {
        background: white;
        padding: 40px;
        z-index: 10001;
        position: relative;
        border-radius: 12px;
    }
}

@media only screen and (max-width: 480px) {
    .sign-left {
        display: none;
    }
    .s-active-line {
        display: none;
    }
    .submit-element {
        justify-content: center;
        display: flex;
    }
    .sign-right {
        background: white;
        padding: 20px;
        z-index: 10001;
        position: relative;
        border-radius: 12px;
        width: 96vw;
        height: 88vh;
    }
    .auto-sign-in {
        display: flex;
        justify-content: center;
        height: 60px;
    }
    .wallet-icon-1 {
        height: 40px;
    }
    .wallet-icon-2 {
        height: 50px;
    }
    .auto-sign-in > a {
        margin-right: 20px;
        padding: 6px 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .sign-right > h2 {
        font-size: 28px;
        margin-bottom: 10px;
    }
    .sign-right > p {
        font-size: 16px;
        margin-bottom: 30px;
    }

    .l-input-element > input {
        width: 100%;
        height: 56px;
        padding: 0 58px;
    }
    .l-input-element > input::placeholder {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        /* identical to box height, or 150% */
        transition: 0.2s;
        letter-spacing: -0.025em;

        /* Text/Dark */

        color: #292930;
    }

    .l-icon {
        position: absolute;
        top: 41.5px;
        left: 21px;
        width: 25px;
    }
    .l-icon-p {
        position: absolute;
        top: 41.5px;
        left: 21px;
        width: 25px;
    }
    /* .fgt-pass {
        text-decoration: none;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;

        text-align: right;
        letter-spacing: -0.025em;
        float: right;
        color: #0b3a6a;
        position: relative;
        left: calc(100vw - 170px);
    } */
}
@media only screen and (max-width: 450px) {
    /* .fgt-pass {
        position: unset;
        left: calc(100vw - 425px) !important;
    } */
}

@media only screen and(max-width:380px) {
    /* .l-input-element > input {
        width: 2800px !important;
        height: 56px;
        padding: 0 72px;
    } */
    /* .fgt-pass {
        left: calc(100vw - 290px) !important;
    } */
    
}
