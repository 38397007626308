@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap"); */
/* @import url("https://fonts.googleapis.com/css2?family=Tajawal:wght@200;300;400;500;700;800;900&display=swap"); */
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://use.typekit.net/ujm1ity.css");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "new-frank", sans-serif !important;
  font-weight: 600;
}

:root {
  --sepaBlack: #29383D;
  --sepaBlue: #1977BC;
  --sepaAccent: #3BB8BC;
  --sepaGreen: #42B094;
  --sepaRed: #D73A52;
  --sepaYellow: #FAC36E;
}

body,
html {
  width: 100% !important;
  height: 100%;
  position: relative;
  overflow-x: hidden !important;
  overflow-y: auto !important;
  scroll-behavior: smooth;
  font-family: "HelveticaNeueBold";
}
.inherit-position {
  position: inherit !important;
  z-index: 1000 !important;
}
.swiper {
  width: 100% !important;
}

.Toastify__toast-container {
  z-index: 1000000000 !important;
}

a {
  text-decoration: none !important;
}

.noScrollBar {
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;
}

.noScrollBar::-webkit-scrollbar {
  display: none !important;
}

.centerMe {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.justifyEnd {
  display: flex !important;
  justify-content: flex-end !important;
}

.not-found {
  text-align: center !important;
  font-size: 20px;
}

.css-quxd4r-MuiInputBase-root {
  width: 100%;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px !important;
  position: relative;
  z-index: 10000000;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
  position: relative;
  z-index: 10000000;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--sepaBlue);
  transition: all 0.3s ease;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--sepaBlue);
  opacity: 0.8;
}

.btn-hover:hover {
  opacity: 0.86;
  cursor: pointer;
}

input:focus,
select:focus {
  outline: 2px solid var(--sepaBlue) !important;
}

.dropdown-item.active,
.dropdown-item:active {
  color: unset !important;
  text-decoration: none !important;
  background-color: unset !important;
}

.field-error {
  color: red !important;
  font-size: 12px;
  margin: 4px 2px;
}

/* react elastic carousel */
.rec-arrow {
  background-color: inherit !important;
  box-shadow: none !important;
}

.rec.rec-arrow:hover {
  color: #f15a22 !important;
}

.rec-dot {
  height: 8px !important;
  width: 8px !important;
}

.rec-dot_active {
  box-shadow: none !important;
  background-color: #f15a22 !important;
}

.section-relative {
  position: relative !important;
}

.btn-primary,
.btn-primary:hover {
  border-color: #f15a22bf !important;
}

.btn-check:focus + .btn-primary,
.btn-primary:focus {
  color: #fff;
  /* background-color: #0b5ed7; */
  border-color: #f15a22bf !important;
  box-shadow: 0 0 0 0.25rem #f15a2287 !important;
}

.css-79ws1d-MuiModal-root {
  position: unset;
  z-index: 130005 !important;
}

.MuiModal-root {
  z-index: 130005 !important;
}

.btn-disable {
  opacity: 0.5 !important;
}

.modal {
  z-index: 1055000 !important;
}

.modal-header {
  border-bottom: 0 !important;
}

.btn-disable:hover {
  cursor: none !important;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.css-1ex1afd-MuiTableCell-root {
  padding-right: 0;
  padding-bottom: 0.5rem;
}
.MuiInputBase-input {
  border-radius: 6px;
  font-family: "HelveticaNeueBold";
}

/* events in mobile View */
.events-mobile-vw {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.events-mobile-vw a {
  margin: 10px;
}

.show-more {
  font-size: 14px;
  color: #f7941d;
  margin: 20px 16px 0 0;
  font-weight: bold;
  text-align: right;
  width: 100%;
}

.show-more:hover {
  cursor: pointer;
  text-decoration: underline !important;
}

.grecaptcha-badge {
  visibility: hidden !important;
}

.googleCaptcha {
  text-align: center;
  font-size: 10px;
  margin-top: 12px;
}

.MuiTableRow-root.css-34nofg-MuiTableRow-root th,
.MuiTableRow-root.css-34nofg-MuiTableRow-root td {
  border: 0;
}

.image_full {
  height: 100% !important;
}

.event__desc__ticket {
  overflow-x: auto !important;
}

@media only screen and (max-width: 792px) {
  ::-webkit-scrollbar {
    width: 0px;
  }
}

@media only screen and (max-width: 520px) {
  .events-mobile-vw a {
    margin: 4px;
  }
  .dropdown-menu[data-bs-popper] {
    right: 0 !important;
    left: unset !important;
  }
}

.MuiTypography-p {
  font-size: 16px !important;
}
.MuiTableCell-root {
  color: #29383D !important;
}
.css-1mggi64-MuiPaper-root-MuiAccordion-root {
  background-color: #fff0 !important;
}
.green-collapse-gradient {
  background-image: linear-gradient(
    to bottom,
    #76e4c8,
    #69dcbc,
    #5cd5b0,
    #4fcda4,
    #42c597
  );
}
.red-collapse-gradient {
  background-image: linear-gradient(
    to bottom,
    #fb8a8b,
    #fa7d7d,
    #f96f6f,
    #f76161,
    #f55253
  );
}
.yellow-collapse-gradient {
  background-image: linear-gradient(
    to bottom,
    #f1c747,
    #edbb3d,
    #e8ae34,
    #e4a22b,
    #df9622
  );
}

.organiserInfo {
  margin-top: 0px !important;
}
.MuiStepLabel-label {
  color: #1977BC !important;
  font-weight: 600 !important;
}
.MuiStepLabel-iconContainer>div {
  color: #29383D !important;
  font-weight: 600 !important;
}
@media only screen and (max-width: 768px) {
  .organiserInfo {
    margin-top: 10px !important;
  }
}

.fixPadding {
  padding: 0 18rem;
}
@media only screen and (max-width: 1586px) {
  .fixPadding {
    padding: 0 6rem;
  }
}

@media only screen and (max-width: 1024px) {
  .fixPadding {
    padding: 0 6rem;
  }
}
@media only screen and (max-width: 762px) {
  .fixPadding {
    padding: 0 0.4rem;
  }
}
.text-ltr {
  animation: floatTextLTR 5s infinite alternate ease-in-out;
}
@-webkit-keyframes floatTextLTR {
  from {
    left: 00%;
  }

  to {
    /* left: auto; */
    left: 100%;
  }
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}