.my-profile {
    box-shadow: 1px 2px 30px 0px #00000017 !important;
    margin: 150px 0 60px 0;
    width: 50vw !important;
    padding: 40px 30px !important;
    flex-direction: column;

    position: relative;
    background: white;
    border-radius: 12px;
}
.my-profile-title {
    font-family: Barlow;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 24px;
    color: #f7941d;
    text-align: left;
    margin-bottom: 16px;
}

.profile-btns {
    display:flex;
}

.profile-input {
    position: relative;
    
    margin-bottom: 12px;

    
}
.submit-btn{
    background: linear-gradient(90deg, #f7941d 5.93%, #f15a22 89.95%);
    width: 90px;
    border-radius: 4px;
    color: white;
    border: 0px;
    padding: 5px 0;
    margin-right:20px;
}
.profile-wallet-btn{
    background: transparent;
    min-width: 90px;
    border-radius: 4px;
    color: black;
    border: 01px solid black;
    padding: 5px 20px;
    margin-right:20px;
}
.profile-wallet-btn:hover {
    cursor: pointer;
    text-decoration: underline;
}
.fa-address-card,
.fa-monument{
    color: #1E428C;
    font-size: 20px;
    position: absolute;
    top: 35px;
    left: 20px;
}
.fa-city {
    color: #1E428C;
    font-size: 17px;
    position: absolute;
    top: 35px;
    left: 10px;
}
.fa-building{
    color: #1E428C;
    font-size: 17px;
    position: absolute;
    top: 35px;
    left: 10px;
}

.fa-envelope {
    color: #1E428C;
    font-size: 20px;
    position: absolute;
    top: 35px;
    left: 13px;
}
.profile-input > input{
    width: 100%;
    height: 40px;
    border-radius: 4px;
    outline: none;
    border: 1px solid #e3e6e9;
    padding-left: 40px;
    

}
.profile-input >img {
    position: absolute;
    top: 31px;
    left: 18px;
}
.profile-photo {
    height: 240px;
    background-color: #f7f7f7;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    font-style: ce;
    align-items: center;
    position: relative !important;
}
.profile-image {
  position: absolute;
  height: 100%;
  width: 100%;
}
.edit-profile-image {
    position: absolute;
    bottom: 12px;
    right: 12px;
    top: 10;
    left: 83%;
    background: url(../../images/edit.svg);
    transition: all 0.3s ease-in-out;
    opacity: 0.9;
    background-repeat: no-repeat;
    color: transparent;
}
.edit-profile-image::file-selector-button {
    display: none;
}
.edit-profile-image:focus {
  outline: 0px solid #F79466 !important;
}

 .l-input-element > .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root,
 .l-input-element > .css-1u3bzj6-MuiFormControl-root-MuiTextField-root > div{
    height: 40px !important;
 }
 .css-1ozefeb {
    z-index: 130000 !important;
}
.edit-profile-image:hover {
    cursor: pointer;
}
@media only screen and (max-width: 1292px) {
    .my-profile {
        width: 70vw !important;
    }
}
@media only screen and (max-width: 992px) {
    .my-profile {
        width: 75vw !important;
    }
}
@media only screen and (max-width: 768px) {
    .my-profile {
        width: 80vw !important;
    }
}
@media only screen and (max-width: 480px) {
    .my-profile {
        width: 90vw !important;
    }
}
