.register-email {
    width: 100%;
    outline: none;
    border-radius: 0px solid transparent;
    border: 0px;
    height: 54px;
    border-radius: 2px;
    padding-left: 20px;
    border:1px solid #29242242;  
}
.register-email::placeholder {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: -0.025em;
    color: #999fae;
}
.register-email:focus {
    /* padding-left: 20px; */
    border:1px solid #29242242 !important;
}
.success-msg {
    color:green !important;
}
.failure-msg {
    color:red !important;
}