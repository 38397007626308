.p-input-element > input {
    width: 100%;
}
.p-icon-p {
    position: relative;
    top: 63px;
    left: 14px;
    width: 25px;
}

.p-input-element > div > label {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */

    letter-spacing: -0.025em;

    /* Color/Dark */

    color: #27272e;
}
.p-input-element > input {
    width: 100%;
    height: 54px;
    /* Color/White */
    background: #ffffff;
    /* Stroke/Super-Light */
    border: 1px solid #e3e6e9;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 0 15px;
}
.p-input-element > input::placeholder {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */
    transition: 0.2s;
    letter-spacing: -0.025em;

    /* Text/Dark */

    color: #999fae;
}
.p-input-element > input:hover,
.p-input-element > input:focus {
    -webkit-box-shadow: 4px 4px 37px -18px rgba(51, 51, 51, 0.61);
    -moz-box-shadow: 4px 4px 37px -18px rgba(51, 51, 51, 0.61);
    box-shadow: 4px 4px 37px -18px rgba(51, 51, 51, 0.61);
    transition: 0.2s;
    outline: none;
    font-size: 16px;
}
