.verify-email {
    margin-top: 140px;
    /* min-height: 80vh; */
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-70%);
    background-color: #ffff;
}

.verify-email-container {
    width: 50vw;
    height: 46vh;
    padding: 48px 48px 48px 48px;
    box-shadow: 1px 2px 30px 0px #00000017;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.verify-email-container > h2 {
    /* color: #f7941d; */
    margin-bottom: 12px;
}
.verify-email-container > p {
    color: #848f9f;
    margin-bottom: 42px;
}

.verify-email-container > button {
    /* background: linear-gradient(90deg, #f7941d 5.93%, #f15a22 89.95%); */
    /* border: 1px solid #f15a22; */
    color: white;
    padding:15px 40px;
    border-radius:12px;
}

@media only screen and (max-width:792px) {
    .verify-email-container {
        width: 80vw;
    }
}
