.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.full-loading {
  z-index: 1055002 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
  background: #ffffff61;
  overflow-y: hidden;
  color: "#0b3a6a";
  top: 0;
  left: 0;
  flex-direction: column;
}
.full-loading-2 {
  background: #060303ad;
  overflow-y: hidden;
  color: "#0b3a6a";
  top: 0;
  flex-direction: column;
  z-index: 1055002 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  position: fixed;
}
.full-loading-2 > div {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: white;
  padding: 30px 20px;
  border-radius: 8px;
}
.payNow {
  color: black;
  margin-top: 58px;
  background: white;
}

@keyframes ldio-50vgi2reltj {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.ldio-50vgi2reltj div {
  box-sizing: border-box !important;
}
.ldio-50vgi2reltj > div {
  position: absolute;
  width: 144px;
  height: 144px;
  top: 28px;
  left: 28px;
  border-radius: 50%;
  border: 16px solid #000;
  border-color: #fa6400 transparent #fa6400 transparent;
  animation: ldio-50vgi2reltj 1s linear infinite;
}
.ldio-50vgi2reltj > div:nth-child(2) {
  border-color: transparent;
}
.ldio-50vgi2reltj > div:nth-child(2) div {
  position: absolute;
  width: 100%;
  height: 100%;
  transform: rotate(45deg);
}
.ldio-50vgi2reltj > div:nth-child(2) div:before,
.ldio-50vgi2reltj > div:nth-child(2) div:after {
  content: "";
  display: block;
  position: absolute;
  width: 16px;
  height: 16px;
  top: -16px;
  left: 48px;
  background: #fa6400;
  border-radius: 50%;
  box-shadow: 0 128px 0 0 #fa6400;
}
.ldio-50vgi2reltj > div:nth-child(2) div:after {
  left: -16px;
  top: 48px;
  box-shadow: 128px 0 0 0 #fa6400;
}
.loadingio-spinner-dual-ring-6gpltyee2mk {
  width: 200px;
  height: 200px;
  display: inline-block;
  overflow: hidden;
  background: #f1f2f3;
}
.ldio-50vgi2reltj {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-50vgi2reltj div {
  box-sizing: content-box;
}
/* generated by https://loading.io/ */
