
.s-in-box {
  width: 40vw;
  height: 100%;
  border-radius: 24px !important;
  z-index: 999;
  margin-bottom:24px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 159px;
  box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
-webkit-box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
-moz-box-shadow: 2px 2px 5px -2px rgba(0,0,0,0.75);
}
.signCloseButtonB {
  position: absolute;
  font-weight: 600;
  top: 12px;
  right: 25px;
  transition: all 0.2s ease-in-out;
  z-index: 999999;
}
.signCloseButtonB > img {
  height: 24px;
}
.signCloseButtonB:hover {
  cursor:pointer;
}
.hide-sign-in {
  display:none;
  transition:all 0.5s ease-out;
}
.s-in-elements {
  padding: 54px 190px 34px 190px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color:white;
  border-radius: 24px;
  height: 100%;
}
.s-in-elements > h2 {
  font-style: normal;
  font-weight: 500;
  font-size: 34px;
  line-height: 100%;
  text-align: center;
  letter-spacing: -0.045em;
  margin-bottom: 16px;
  color: #292930;
}
.s-in-elements > p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.025em;
  margin-bottom: 30px;
  color: #999fae;
}
.input-element {
  flex-direction: column;
  position: relative;
  margin-top:2px;
}
.input-element > label {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: -0.025em;
  color: #27272e;
}
.input-element > input {
  width: 100%;
  height: 50px;
  background: #ffffff;
  border: 1px solid #e3e6e9;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 15px 48px;
}
.input-element > input:focus,
.input-element >input:hover {
  -webkit-box-shadow: 4px 4px 37px -18px rgba(51, 51, 51, 0.61);
  -moz-box-shadow: 4px 4px 37px -18px rgba(51, 51, 51, 0.61);
  box-shadow: 4px 4px 37px -18px rgba(51, 51, 51, 0.61);
  outline: none;
  padding: 15px 44px;
  transition: 0.3s;
}
.input-element > input::placeholder {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  transition:0.2s;
  letter-spacing: -0.025em;
  color: #292930;
}
.icon {
  position: relative;
  top: 40px;
  left: -28px;
}
.icon-p {
  position: relative;
  top: 40px;
  left: -48px;
}
.icon-p2 {
  position: relative;
  top: 40px;
  left: -108px;
}
 
.submit-element {
  margin-top: 34px;
  display: flex;
  flex-direction: row;
}
.btnFull {
  width:100% !important;
}
.sub-sign-up {
  background: linear-gradient(90deg, #f7941d 5.93%, #f15a22 89.95%);
  border: 1px solid #f15a22;
  box-sizing: border-box;
  border-radius: 10px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  width: 45%;
  height: 53px;
  text-align: center;
  letter-spacing: -0.01em;
  color: #000000;
}
.sub-sign-up:hover {
  cursor: pointer;
  opacity: 0.9;
}
.sub-sign-in {
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  background: none;
  text-align: right;
  letter-spacing: 0.015em;
  color: #000000;
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  padding: 24px 56px;
}
.sub-sign-in:hover {
  cursor: pointer;
  opacity: 0.8;
}
.register-message {
  color:green;
  font-size:14px;
  /* margin-top:10px; */
  z-index: 100000000;
  position: absolute;
}
.error-message {
  color:red;
  font-size:14px;
  margin-top:10px;
}

.star-trans {
  position: absolute;
  width: 45px;
  height: 45px;
  left: 176.98px;
  top: 214.44px;
}
.hexagon-yellow {
  position: absolute;
  width: 34px;
  height: 34px;
  left: 159px;
  top: 546px;
  opacity: 0.7;
}
.active-line {
  position: absolute;
  width: 69px;
  height: 2px;
  left: 243px;
  top: 846px;
}
.hexagon-2 {
  position: absolute;
  width: 30px;
  height: 30px;
  left: calc(100vw - 90px);
  top: 140.21px;
}
.star-yellow {
  position: absolute;
  width: 95px;
  height: 95px;
  left: calc(100vw - 300px);
  top: 220px;
}
.hexagon-orange {
  position: absolute;
  width: 27px;
  height: 27px;
  left: calc(100vw - 100px);
  top: 629px;
}
.hexagon-blue {
  position: absolute;
  width: 158px;
  height: 158px;
  left: calc(100vw - 200px);
  top: calc(200vh - 600px);
}


@media only screen and (max-width:1292px) {
  .s-in-elements {
      padding:44px 150px 34px 150px;
  }
}
@media only screen and (max-width:992px) {
  .s-in-elements {
      padding:44px 60px 34px 60px;
  }
  
  .s-in-elements > h2 {
      font-size: 30px;
      margin-bottom:12px;
  }
  .s-in-elements > p {
      font-size: 14px;
      margin-bottom:18px;
  }
  .input-element {
      /* display: flex; */
      flex-direction: column;
  }
  /* .input-element > input {
    height:38px;
  } */
  .icon {
      position: absolute;
      top: 30px;
      left: 15px;
      width:32px;
  }
  .icon-p {
      position: absolute;
      top: 30px;
      left: 13px;
      width:32px;
  }
  .icon-p2 {
      position: absolute;
      top: 30px;
      left: 13px;
      width:32px;
  }
}
@media only screen and (max-width:580px) {
  .s-in-box {
      /* margin-top:125px; */
      width: 90vw;
      /* min-height:130vh; */
  }
  .input-element > input {
      width: 100%;
      /* height: 38px; */

  
      background: #ffffff;

  
      border: 1px solid #e3e6e9;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 15px 48px;
  }
  .input-element > input::placeholder {
   
    font-size: 14px;
    
  }
  .icon {
      position: absolute;
      top: 30px;
      left: 15px;
      width:32px;
  }
  .icon-p {
      position: absolute;
      top: 30px;
      left: 13px;
      width:32px;
  }
  .icon-p2 {
      position: absolute;
      top: 30px;
      left: 13px;
      width:32px;
  }
  .sub-sign-up {
      padding: 20px 40px;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
  }
  .sub-sign-in{
      padding:20px 40px;
  }
}
@media only screen and (max-width:480px) {
  .s-in-box {
      width: 96vw;
  }
  .input-element > input {
      width: 100%;
      height: 38px;

  
      background: #ffffff;
      border: 1px solid #e3e6e9;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 15px 48px;
  }
  .icon {
      top: 30px;
      left: 15px;
      width:32px;
  }
  .icon-p {
      top: 30px;
      left: 13px;
      width:32px;
  }
  .icon-p2 {
      top: 30px;
      left: 13px;
      width:32px;
  }
  .submit-element {
    margin-top: 30px;
  }
  .sub-sign-up {
      padding: 20px 18px;
      font-size: 16px;
      line-height: 22px;
      color: #000000;
  }
  .sub-sign-in{
      padding:20px 40px;
  }
  /* .fgt-pass {
      position:unset;
      left: calc(100vw - 495px) !important;
  } */
}
